import * as React from 'react';

import * as Components from '../../pageSections/post';
import Template from '../../pageSections/templates/main';

import { graphql } from 'gatsby';

export default function Home({ data }) {

    return (
        <React.Fragment> 
            {/* place your sections here from both Template and Page imports */}
            
        </React.Fragment>
    )
}


export const query = graphql`
    query PostPageQuery($postId: String) {
        datoCmsPost(id: { eq: $postId }) {
            id
            htmlcontent
        }
    }
`